<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.openingWeekdays') }}</h3>
    <div class="flix-form-group" v-for="(weekday, index) in $t('constants.weekdays')" :key="index">
      <a href="#" class="flix-html-a" @click.prevent="setStatus(index)" :key="updateKey">
        <flixIcon v-if="!weekdays[index] || !weekdays[index].length" class="icon" :id="'unchecked'" />
        <flixIcon v-else class="icon" :id="'check'" />
        <h4 class="flix-html-h4">{{ weekday }}</h4>
      </a>

      <transition name="flixFadeIn">
        <timepicker style="margin-left: 30px" v-if="weekdays[index] && weekdays[index].length" :index="index" :callback="function(data){setData(data)}" :weekdays="weekdays" :key="JSON.stringify(weekdays[index])"/>
      </transition>
    </div>

    <pause :data="data" :key="updateKey" :callback="function(data){setData(data)}" v-if="Object.keys(weekdays).length"/>

    <div class="flix-form-group" :key="key" >
      <hr v-if="getData()" class="flix-html-hr" />
      <saveBtn v-if="getData()" :onClick="function () { saveData() }"><template v-slot:text>{{ $t('message.save', {name: $tc('message.timeRange', 2)}) }}</template></saveBtn>
    </div>
  </div>
</template>
<script>
export default {
  components: {

    pause () { return import('@/components/assistent/components/datepicker/pause') },
    timepicker () { return import('@/components/assistent/components/assets/timepicker') }
  },
  props: { data: Object, callback: Function },
  data () {
    if (typeof this.data.include === 'undefined') {
      this.data.include = {}
    }
    if (typeof this.data.include.weekdays === 'undefined') {
      this.data.include.weekdays = {}
    }
    return {
      weekdays: this.data.include.weekdays,
      updateKey: '',
      error: false,
      includeData: this.data.include.weekdays,
      excludeData: this.data.exclude.weekdays,
      key: new Date().getTime()
    }
  },
  methods: {
    saveData () {
      for (var i = 0; i <= 6; i++) {
        if (Object.keys(this.includeData).indexOf(i.toString()) === -1) {
          this.includeData[i] = []
        }
      }
      var data = { include: { weekdays: this.includeData, buffer: this.data.include.buffer, period: this.data.include.period }, exclude: { weekdays: this.excludeData } }
      this.callback(data)
    },
    getData () {
      return Object.keys(this.includeData).length
    },
    setData (data) {
      if (typeof data.include !== 'undefined') {
        this.includeData = data.include.weekdays
      }
      if (typeof data.exclude !== 'undefined') {
        this.excludeData = data.exclude.weekdays
      }
      this.key = new Date().getTime()
    },
    setStatus (index) {
      if (typeof this.weekdays[index] !== 'undefined') {
        delete this.weekdays[index]
        this.updateKey = new Date().getTime()
        return false
      }
      this.weekdays[index] = ['8:00', '18:00', 60]
      this.updateKey = new Date().getTime()
      this.includeData = this.weekdays
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>
    .icon
      font-size: 16pt
      float: left
      margin-right: 5px
  </style>
